import "./bootstrap.scss";

import "./adminlte/adminlte.js";
import "bootstrap/js/src/dropdown.js";
import "bootstrap/js/src/tab.js";
import Modal from "bootstrap/js/src/modal.js";
import "bootstrap/js/src/collapse.js";
import Tooltip from "bootstrap/js/src/tooltip.js";
import "bootstrap4-toggle/js/bootstrap4-toggle.min";
import "jquery-simplecolorpicker/jquery.simplecolorpicker.js";
import  'daterangepicker/daterangepicker';

import 'simplebar/dist/simplebar.css';
import SimpleBar from "simplebar";

window.bootstrap = {Tooltip, Modal};
window.SimpleBar = SimpleBar;
