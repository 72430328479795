import $ from 'jquery'
import CardRefresh from 'admin-lte/build/js/CardRefresh'
import CardWidget from 'admin-lte/build/js/CardWidget'
import ControlSidebar from 'admin-lte/build/js/ControlSidebar'
import DirectChat from 'admin-lte/build/js/DirectChat'
import Dropdown from 'admin-lte/build/js/Dropdown'
import ExpandableTable from 'admin-lte/build/js/ExpandableTable'
import Fullscreen from 'admin-lte/build/js/Fullscreen'
// import IFrame from 'admin-lte/build/js/IFrame'
import Layout from 'admin-lte/build/js/Layout'
import PushMenu from 'admin-lte/build/js/PushMenu'
import SidebarSearch from 'admin-lte/build/js/SidebarSearch'
import NavbarSearch from 'admin-lte/build/js/NavbarSearch'
import Toasts from 'admin-lte/build/js/Toasts'
import TodoList from 'admin-lte/build/js/TodoList'
import Treeview from 'admin-lte/build/js/Treeview'

export {
    CardRefresh,
    CardWidget,
    ControlSidebar,
    DirectChat,
    Dropdown,
    ExpandableTable,
    Fullscreen,
    // IFrame,
    Layout,
    PushMenu,
    SidebarSearch,
    NavbarSearch,
    Toasts,
    TodoList,
    Treeview
};

window.$ = window.jQuery = $;
